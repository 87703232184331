import {
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import i18n from 'i18next';
import { actions as authActions, types as authTypes } from 'ducks/auth/actions';
import { actions as preferencesActions } from 'ducks/preferences/actions';
import { actions as dataSourcesActions } from 'ducks/dataSources/actions';

import { IPublicClientApplication } from '@azure/msal-browser';

import tokenStorage from 'services/tokenStorage';
import featureStorage from 'services/featureStorage';
import { openBasicToast, openErrorToast, openSuccessToast } from 'state/Toast';

import {
  checkTokenValidity,
  deleteAccount,
  getAnnouncements,
  getClientId,
  getCustomer,
  getUserDetails,
  login,
  logMessage,
  logout,
  requestEmailToken,
  sendWhoAmI,
  setCustomer,
  updateUser,
} from 'services/api';
import {
  LOGOUT_URL,
  ZEBRA_EMAIL_EXTENSION,
  ZEBRA_LOGIN_URL,
} from 'utils/config';
import { CustomerInfoType, State } from 'zsbpsdk/src/customer';
import { Severity } from 'utils/errorFormatter';
import {
  getCustomer as getCustomerSelector,
  getUser as getTheUser,
} from 'ducks/auth/selectors';
import { incrementBannerMessageDisplayCount } from '../utils/bannerMessages';
import { BannerMessage } from 'zsbpsdk/proto/ZsbpPortalService_pb';
import { buildSandboxIdCookie } from '../utils/cookie';
import { PayloadAction } from '@reduxjs/toolkit';
import selectAwaitCustomer from 'utils/selectAwaitCustomer';
import { HOUR_IN_SECONDS, SECOND_IN_MILLISECONDS } from '../utils/config.json';
import { setDeleteAccountStarted } from 'utils/deleteAccountStarted';
import { apiCall } from './utils';
import { getDithering, getUnit } from 'ducks/preferences/selectors';
import Unit from '../utils/unitFormatter';

const LOGGING_IN_KEY = 'loggingIn';
const PREVIOUS_FAILED_TOKEN_KEY = 'previousFailedToken';

export function* initialLoginStatus(): Generator {
  const currentToken = tokenStorage.get();
  if (currentToken) {
    yield put(authActions.INITIAL_STATUS.IS_LOADING(true));
    const { token } = currentToken;
    const customer: CustomerInfoType = (yield select(getCustomerSelector)) as CustomerInfoType;
    const isValid = yield apiCall(checkTokenValidity, token, customer?.id);
    if (isValid) {
      localStorage.removeItem(PREVIOUS_FAILED_TOKEN_KEY);
      try {
        //TODO: Check token validity
        
        if (localStorage.getItem(LOGGING_IN_KEY) != null) {
          yield call(() => {
            localStorage.removeItem(LOGGING_IN_KEY);
          });
          
          const customer: CustomerInfoType = (yield apiCall(getCustomer, token)) as CustomerInfoType;
          const clientId = getClientId();
          const dithering: number = (yield select(getDithering)) as number;
          const unit: Unit = (yield select(getUnit)) as Unit;

          yield apiCall(
            sendWhoAmI,
            unit?.abbreviation,
            dithering,
            customer?.id,
            token,
            clientId,
          );

          const bannerMessages: BannerMessage[] = (yield apiCall(
            getAnnouncements,
            customer?.id,
            token,
          )) as BannerMessage[];


          yield call(() => {
            incrementBannerMessageDisplayCount(bannerMessages, customer);
          });
        }
        
        yield put(
          authActions.LOGIN.success({
            token: currentToken,
          }),
        );
        yield put(authActions.LOAD_FEATURES.success(featureStorage.get()));

        yield put(authActions.CUSTOMER.request({}));
        yield take([authTypes.CUSTOMER.SUCCESS, authTypes.CUSTOMER.FAILURE]);
        yield put(preferencesActions.ALL.request({}));


        // TODO: check url to see where intented route was and continue
      } catch (e: any) {
        console.error(e);
      } finally {
        yield put(authActions.INITIAL_STATUS.IS_LOADING(false));
      }
    } else {
      yield put(authActions.INITIAL_STATUS.IS_LOADING(false));
      if (window.location.pathname !== '/cloud') {
        tokenStorage.clear();
        if (localStorage.getItem(PREVIOUS_FAILED_TOKEN_KEY) === token) {
          window.location.assign(LOGOUT_URL);
        } else {
          yield put(replace('/login'));
        }

        localStorage.setItem(PREVIOUS_FAILED_TOKEN_KEY, token);
      }
    }
  } else {
    if (window.location.pathname !== '/cloud') {
      tokenStorage.clear();
      yield put(replace('/login'));
      localStorage.removeItem(PREVIOUS_FAILED_TOKEN_KEY);
    }
  }
}

function* handleGetUser({ payload: customer }: any) {
  try {
    const user = yield apiCall(getUserDetails, customer);
    yield put(authActions.USER.success(user));
  } catch (e: any) {
    yield put(authActions.USER.failure({ e, error: e }));
    if (e.code === 401) {
      console.error('401 Recieved from user api call');
      // TODO: Re implement this once SSO update their API
      // try {
      //   const { token } = tokenStorage.get();
      //   const customer: CustomerInfoType = yield call(getCustomer, token);
      //   yield put(authActions.CUSTOMER.success(customer));
      //   const user = yield call(getUserDetails, customer);
      //   yield put(authActions.USER.success(user));
      // } catch (e: any) {
      //   yield put(authActions.LOGOUT());
      // }
    }
  }
}

function* handleGetCustomer() {
  const { token } = tokenStorage.get();
  try {
    const customer: CustomerInfoType = yield apiCall(getCustomer, token);

    if (
      customer.eulaAcceptedVersion === null ||
      customer.eulaAcceptedVersion === ''
    ) {
      yield put(replace('/eula'));
    }
    yield put(authActions.CUSTOMER.success(customer));

    if (!customer.email.includes(ZEBRA_EMAIL_EXTENSION)) {
      yield put(authActions.USER.request(customer));
    }
  } catch (e: any) {
    yield put(authActions.CUSTOMER.failure({ e, error: e }));
  }
}

const parseSessionId = (sessionCookie: string): string => {
  const sessionRegex = /^SESSION=([a-zA-Z0-9]+);/;
  const sessionId = sessionCookie.match(sessionRegex);
  return sessionId?.[1] || '';
};

function* handleLogin() {
  try {
    document.cookie = buildSandboxIdCookie();
    yield apiCall(login);
  } catch (err: any) {
    const sessionCookie = err.message.split('Set-Cookie: ')[1];
    document.cookie = sessionCookie;

    yield call(tokenStorage.set, parseSessionId(sessionCookie));
    yield call(() => {
      localStorage.setItem(LOGGING_IN_KEY, String(true));
    });

    window.location.assign(ZEBRA_LOGIN_URL);
  }
}

function* handleLogout() {
  const storage = tokenStorage.get();
  if (storage === 'null' || storage === null) {
    tokenStorage.clear();
    window.location.assign(LOGOUT_URL);
    return;
  }

  try {
    const customer = yield select(getCustomerSelector);
    yield apiCall(logout, customer?.id, storage.token);
    yield put(dataSourcesActions.GOOGLE_ACCOUNT.clear());
  } catch (e: any) {
    console.error(e);
  } finally {
    tokenStorage.clear();

    setTimeout(() => window.location.assign(LOGOUT_URL), 1000);
  }
}
function* handleUpdateCustomer({ payload }: PayloadAction<CustomerInfoType>) {
  const { token } = tokenStorage.get();

  const newCustomer = payload;
  const customer = yield select(getCustomerSelector);
  const success = yield apiCall(setCustomer, newCustomer, token);

  if (success) {
    yield put(authActions.UPDATE_CUSTOMER.success(newCustomer));

    if (
      newCustomer.avatar !== 'null' &&
      customer.avatar !== newCustomer.avatar
    ) {
      yield put(
        openBasicToast({ title: i18n.t('settings:avatar-change-successful') }),
      );
    }
  } else {
    yield put(authActions.UPDATE_CUSTOMER.failure({ success }));
    yield put(openErrorToast(`User account could not be updated`));
  }
}

function isUnauthenticated(statusCode: number) {
  return (
    statusCode === 16 || // gRPC status code for UNAUTHENTICATED
    statusCode === 401
  ); // HTTP status code for Unauthorized
}

function isForbidden(statusCode: number) {
  return (
    statusCode === 7 || // gRPC status code for PERMISSION_DENIED
    statusCode === 403
  ); // HTTP status code for Forbidden
}

function* handleApiFailure({
  payload,
  type,
}: PayloadAction<{ error: { code: number; message: string }; type: string }>) {
  const customer = yield select(getCustomerSelector);
  const token = tokenStorage.get();
  const error = payload && payload.error;
  
  if (error && error.code) {
    const unauthenticated = isUnauthenticated(error.code);
    const forbidden = isForbidden(error.code);
    try {
      if (!unauthenticated && !forbidden) {
        yield apiCall(
          logMessage,
          Severity.Error,
          `Communication:${error.code < 100 ? 'gRPC' : 'HTTP'}-${error.code}`,
          `${type}: ${error.message}`,
          customer?.id ?? '',
          token?.token ?? '',
        );
      }
      console.error(
        `%cLOGGED-ERROR: ${error.code < 100 ? 'gRPC' : 'HTTP'}-${
          error.code
        }.${type}: ${error.message}`,
        'background:#FFF0F0;color:red',
      );
    } finally {
      if (unauthenticated || forbidden) {
        console.error('UNAUTHORIZED', payload, type);
        yield* handleLogout();
      }
    }
  } else {
    if (!token) {
      console.error('UNAUTHORIZED', payload, type);
      yield* handleLogout();
    }
    console.error('ERROR', payload, type);
  }
}

function* handleGetEmailToken({ payload }: any) {
  try {
    const customer = yield select(getCustomerSelector);
    const { zuid } = yield select(getTheUser);

    const emailToken = yield apiCall(
      requestEmailToken,
      payload,
      zuid,
      customer?.id,
      customer.token,
    );
    yield put(authActions.EMAIL_TOKEN.success(emailToken));
    yield put(openSuccessToast(i18n.t('login:verification-email')));
  } catch (e: any) {
    yield put(authActions.EMAIL_TOKEN.failure(e));
  }
}

function* handleUpdateFirstName({ payload }: PayloadAction<string>) {
  const firstName = payload;

  const { zuid, userId } = yield select(getTheUser);
  const customer = yield select(getCustomerSelector);
  
  try {
    // This has been changed for resolving SMBUI-2711
    // Our backend has functionallity to check whether the token is valid, 
    // and if it is expired - to obtain a new valid token.
    const currentToken = tokenStorage.get();
    const customerGRPC: CustomerInfoType = yield apiCall(getCustomer, currentToken.token);
    
    const success = yield apiCall(
      updateUser,
      zuid,
      userId,
      { firstName },
      customer?.id,
      customerGRPC.token,
    );

    if (!success) {
      throw new Error(i18n.t('settings:unable-to-update-first-name'));
    }

    yield put(authActions.UPDATE_FIRSTNAME.success(payload));
  } catch (e: any) {
    yield put(authActions.UPDATE_FIRSTNAME.failure({ e, error: e }));
    yield put(openErrorToast(i18n.t('settings:unable-to-update-first-name')));
  }
}

function* handleUpdateLastName({ payload }: PayloadAction<string>) {
  const lastName = payload;

  const { zuid, userId } = yield select(getTheUser);
  const customer = yield select(getCustomerSelector);

  try {
    // This has been changed for resolving SMBUI-2711
    // Our backend has functionallity to check whether the token is valid, 
    // and if it is expired - to obtain a new valid token.
    const currentToken = tokenStorage.get();
    const customerGRPC: CustomerInfoType = yield apiCall(getCustomer, currentToken.token);
    
    const success = yield apiCall(
      updateUser,
      zuid,
      userId,
      { lastName },
      customer?.id,
      customerGRPC.token,
    );

    if (!success) {
      throw new Error(i18n.t('settings:unable-to-update-last-name'));
    }

    yield put(authActions.UPDATE_LASTNAME.success(payload));
  } catch (e: any) {
    yield put(authActions.UPDATE_LASTNAME.failure({ e, error: e }));
    yield put(openErrorToast(i18n.t('settings:unable-to-update-last-name')));
  }
}

function* handleUpdateEmail({ payload }: any) {
  const { zuid, userId } = yield select(getTheUser);
  const customer = yield select(getCustomerSelector);
  try {
    const success = yield apiCall(
      updateUser,
      zuid,
      userId,
      {
        userId: payload.newEmail,
        emailId: payload.newEmail,
        token: payload.userToken,
      },
      customer?.id,
      customer.token,
    );
    if (success) {
      yield put(authActions.UPDATE_EMAIL.success(payload));
    } else {
      throw new Error('Unable to update email');
    }
  } catch (e: any) {
    yield put(authActions.UPDATE_EMAIL.failure({ e, error: e }));
  }
}

function* handleDeleteAccount({
  payload,
}: PayloadAction<{ state: State; msalInstance?: IPublicClientApplication }>) {
  const { state, msalInstance } = payload;

  const { token } = tokenStorage.get();
  const { id }: CustomerInfoType = yield selectAwaitCustomer();

  try {
    const success = yield call(deleteAccount, state, id, token);

    if (state === State.INITIATE) {
      const deleteExpiration =
        new Date().getTime() + HOUR_IN_SECONDS * SECOND_IN_MILLISECONDS;

      setDeleteAccountStarted({ customerId: id, deleteExpiration });
    } else {
      yield msalInstance?.logout({
        onRedirectNavigate: () => false,
      });
    }

    yield put(authActions.DELETE_ACCOUNT.success({ success, state }));

    if (state === State.CONFIRM) return;

    yield* handleLogout();
  } catch (err: any) {
    if (err.code === 9) {
      yield put(
        authActions.DELETE_ACCOUNT.failure({
          title: i18n.t('settings:delete-account-timeout-session-title'),
          description: i18n.t(
            'settings:delete-account-timeout-session-description',
          ),
          state,
        }),
      );

      return;
    }

    yield put(
      authActions.DELETE_ACCOUNT.failure({
        title: i18n.t('settings:delete-account-error'),
        description: i18n.t('settings:account-unable-to-be-deleted'),
        state,
      }),
    );
  }
}

export default function* watcher() {
  yield fork(initialLoginStatus);
  yield takeLatest(authTypes.LOGIN.REQUEST, handleLogin);
  yield takeLatest(authTypes.LOGOUT, handleLogout);
  yield takeLatest(authTypes.UPDATE_CUSTOMER.REQUEST, handleUpdateCustomer);
  yield takeLatest(authTypes.USER.REQUEST, handleGetUser);
  yield takeLatest(authTypes.CUSTOMER.REQUEST, handleGetCustomer);
  yield takeLeading((action) => /FAILURE$/.test(action.type), handleApiFailure);
  //TODO handle API errors yield takeLatest(authTypes.CUSTOMER.FAILURE, handleApiFailure);
  yield takeLatest(authTypes.EMAIL_TOKEN.REQUEST, handleGetEmailToken);
  yield takeLatest(authTypes.UPDATE_FIRSTNAME.REQUEST, handleUpdateFirstName);
  yield takeLatest(authTypes.UPDATE_LASTNAME.REQUEST, handleUpdateLastName);
  yield takeLatest(authTypes.UPDATE_EMAIL.REQUEST, handleUpdateEmail);
  yield takeLatest(authTypes.DELETE_ACCOUNT.REQUEST, handleDeleteAccount);
}
